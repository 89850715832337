import io from 'socket.io-client';

const BATCHRUNNER_WEBSOCKETS = process.env.REACT_APP_BATCHRUNNER_WEBSOCKETS;
const socket = io(BATCHRUNNER_WEBSOCKETS, { path: '/socket.io' });
const KNOWN_EVENTS = {
  PROGRESS_UPDATE: 'scenario_progress_update',
  BATCH_STATUS_UPDATE: 'batch_status_update',
  SCENARIO_STATUS_UPDATE: 'scenario_status_update',
  NEW_BATCH: 'batch_new_batch',
  NEW_SCENARIOS: 'batch_new_scenarios',
};

export default socket;
export {
  KNOWN_EVENTS,
};
