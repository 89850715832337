import { createSlice, configureStore } from 'redux-starter-kit';
import initialState from 'state';

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  // eslint-disable-next-line
  }, deps ? [dispatch, ...deps] : [dispatch]);
}

const { actions: toolScenariosActions, reducer: toolScenarioReducer } = createSlice({
  slice: 'toolScenarios',
  initialState: initialState.toolScenarios,
  reducers: {
    addToolScenarios: (state, action) => {
      // find existing scenarios and replace them
      action.payload.forEach((scenario) => {
        const index = state.findIndex(s => s.id === scenario.id);
        if (index > -1) {
          state.splice(index, 1);
        }
      });

      state.push(...action.payload);
    },
  },
});

const { actions: selectedActions, reducer: selectedReducer } = createSlice({
  slice: 'selected',
  initialState: initialState.selected,
  reducers: {
    setSelected: (state, action) => action.payload,
  },
});

const { actions: batchesActions, reducer: batchesReducer } = createSlice({
  slice: 'batches',
  initialState: initialState.batches,
  reducers: {
    addBatches: (state, action) => {
      // replace existing batches...
      action.payload.forEach((batch) => {
        const index = state.findIndex(b => b.batch_id === batch.batch_id);
        if (index > -1) {
          // splice the element out from the state
          state.splice(index, 1);
        }
      });

      state.push(...action.payload);
    },

    addBatch: (state, action) => {
      state.push(action.payload);
    },

    // called from websockets
    updateBatchStatusWs: (state, action) => {
      const batch = state.find(b => b.batch_id === action.payload.batch_id);
      batch.status = action.payload.status;
      return state;
    },

    removeBatch: (state, action) => {
      const index = state.findIndex(batch => batch.batch_id === action.payload);
      if (index > -1) {
        state.splice(index, 1);
      }
    },
  },
});

const { actions: batchScenariosActions, reducer: batchScenariosReducer } = createSlice({
  slice: 'batchScenarios',
  initialState: initialState.batchScenarios,
  reducers: {
    addBatchScenarios: (state, action) => {
      const obj = state;
      if (!Object.prototype.hasOwnProperty.call(obj, action.payload.batchID)) {
        // eslint-disable-next-line no-param-reassign
        obj[action.payload.batchID] = [];
      }

      // eslint-disable-next-line no-param-reassign
      const scenarios = action.payload.scenarios.map(s => ({
        id: s.id,
        status: s.status,
        progress: s.progress,
        time_left: s.time_left,
        budget: s.budget,
        catchments: s.catchments.map(c => ({
          id: c.id,
          risk_change_tss: c.risk_change_tss,
          risk_change_bacteria: c.risk_change_bacteria,
          risk_change_virus: c.risk_change_virus,
          risk_change_protozoa: c.risk_change_protozoa,
          pu_properties_change: c.pu_properties_change,
        })),
        efficacy_change: s.efficacy_change,
        intervention_budget_change: s.intervention_budget_change,
        max_steps: s.max_steps,
        s_max_steps: s.s_max_steps,
        tmax: s.tmax,
        tmin: s.tmin,
        has_changes: s.has_changes,
      }));
      obj[action.payload.batchID] = scenarios;
      return obj;
    },

    // called from websockets
    // TODO: state mutation error occuring here
    addBatchScenarioIDsWs: (state, action) => {
      if (!Object.prototype.hasOwnProperty.call(state, action.payload.batch_id)) {
        return state;
      }

      const scenarios = action.payload.scenario_ids.map(id => ({
        id,
        status: '',
        progress: 0,
        time_left: 0,
      }));
      state[action.payload.batch_id] = scenarios;
      return state;
    },

    // called from websockets
    updateScenarioProgressWs: (state, action) => {
      const obj = state;
      if (!Object.prototype.hasOwnProperty.call(obj, action.payload.batch_id)) {
        return obj;
      }

      const scenarios = obj[action.payload.batch_id];
      const scenario = scenarios.find(s => s.id === action.payload.scenario_id);
      if (scenario === undefined) {
        return obj;
      }

      scenario.progress = action.payload.progress;
      scenario.time_left = action.payload.time_remaining;
      return obj;
    },
  },
});

const store = configureStore({
  reducer: {
    toolScenarios: toolScenarioReducer,
    selected: selectedReducer,
    batches: batchesReducer,
    batchScenarios: batchScenariosReducer,
  },
});
const { addToolScenarios } = toolScenariosActions;
const { setSelected } = selectedActions;
const {
  addBatches, addBatch, updateBatchStatusWs, removeBatch,
} = batchesActions;
const {
  addBatchScenarios, addBatchScenarioIDsWs, updateScenarioProgressWs,
} = batchScenariosActions;

export {
  store,
  useActions,
  addToolScenarios,
  setSelected,
  addBatches, addBatch, updateBatchStatusWs, removeBatch,
  addBatchScenarios, addBatchScenarioIDsWs, updateScenarioProgressWs,
};
