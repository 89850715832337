const { BlobServiceClient } = require('@azure/storage-blob');

// async function blobToString(blob) {
//   const reader = new FileReader();
//   return new Promise((resolve, reject) => {
//     reader.onloadend = $event => resolve($event.target.result);
//     reader.onerror = reject;
//     reader.readAsText(blob);
//   });
// }

// returns a true/false if the file exists in the azure storage or not
export const isFileAvailableInBlob = async (fileName) => {
  const uri = process.env.REACT_APP_AZURE_BLOB_CONNECTION_URI;
  const container = process.env.REACT_APP_AZURE_BLOB_CONTAINER;

  const blobServiceClient = BlobServiceClient.fromConnectionString(uri);
  const containerClient = await blobServiceClient.getContainerClient(container);
  const blobClient = containerClient.getBlobClient(fileName);
  const doesExist = await blobClient.exists();

  return doesExist;
};

export const downloadFileFromBlob = async (fileName) => {
  const uri = process.env.REACT_APP_AZURE_BLOB_CONNECTION_URI;
  const container = process.env.REACT_APP_AZURE_BLOB_CONTAINER;

  const blobServiceClient = BlobServiceClient.fromConnectionString(uri);
  const containerClient = await blobServiceClient.getContainerClient(container);
  const blobClient = containerClient.getBlobClient(fileName);
  const resp = await blobClient.download();

  // const downloaded = await blobToString(await resp.blobBody);
  const downloaded = await resp.blobBody;
  return downloaded;
};

export const getDownloadURIForBlob = async (fileName) => {
  const uri = process.env.REACT_APP_AZURE_BLOB_CONNECTION_URI;
  const container = process.env.REACT_APP_AZURE_BLOB_CONTAINER;

  const blobServiceClient = BlobServiceClient.fromConnectionString(uri);
  const containerClient = await blobServiceClient.getContainerClient(container);
  const blobClient = containerClient.getBlobClient(fileName);
  return blobClient.url;
};

export const CurrencyFormatter = Intl.NumberFormat('en-au', {
  maximumSignificantDigits: 21,
  currency: 'AUD',
  style: 'currency',
});

export function formatScenarioTimeRemaining(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - (hours * 3600)) / 60);
  let secs = seconds - (hours * 3600) - (minutes * 60);
  secs = Math.round(secs);

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secs < 10) {
    secs = `0${secs}`;
  }

  return `${hours}:${minutes}:${secs}`;
}

export default null;
