import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AnimatedDots } from './truii-dots.svg';

const Loader = ({ message }, ...props) => {
  const style = {
    // position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={style}>
      <div className="row justify-content-center text-center">
        <div className="col-12 mb-4">
          <AnimatedDots width="200px" />
        </div>
        <div className="col-12">
          <h5>
            {message}
          </h5>
        </div>
      </div>
    </div>
  );
};
Loader.propTypes = {
  message: PropTypes.string,
};
Loader.defaultProps = {
  message: '',
};

export default Loader;
