import React from 'react';
import ReactDOM from 'react-dom';

// scenes
import App from 'scenes/app';

// redux
import { Provider } from 'react-redux';
import { store } from './store';

// auth0
import { Auth0Provider } from './react-auth0-spa';

import * as serviceWorker from './serviceWorker';

import './index.scss';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URL;

const render = (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    client_id={AUTH0_CLIENT_ID}
    redirect_uri={AUTH0_CALLBACK_URL}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);
ReactDOM.render(render, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
