import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';

import { auth } from 'requests';

import SetupScene from 'scenes/setup';
import LibraryScene from 'scenes/library';

// websockets
import socket, { KNOWN_EVENTS } from '../../websocket';

import './App.scss';

export const SocketContext = React.createContext({
  batches: {},
  scenarios: {},
});

export const useWebsockets = () => useContext(SocketContext);

const Auth = ({ children }) => {
  const {
    auth0Client, loading,
    isAuthenticated, loginWithRedirect, getIdTokenClaims,
  } = useAuth0();

  const [token, setToken] = useState(null);

  useEffect(() => {
    const getTokenAndStoreInRequests = async () => {
      const claims = await getIdTokenClaims();
      // eslint-disable-next-line no-underscore-dangle
      const tkn = claims.__raw;
      setToken(tkn);

      auth.token = tkn;
    };

    if (!auth0Client) {
      return;
    }

    if (loading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({});
      return;
    }

    // we are authenticated
    // i hope...
    if (token === null) {
      getTokenAndStoreInRequests();
    }
  }, [auth0Client, loading, isAuthenticated, token, loginWithRedirect, getIdTokenClaims]);

  // return loading screen?
  if (token === null) {
    return null;
  }

  return children;
};

export default class AppRouter extends React.Component {
  state = {
    batches: {},
    scenarios: {},
  }

  constructor(props) {
    super(props);

    this.handleProgressUpdateWs = this.handleProgressUpdateWs.bind(this);
    this.handleBatchStatusUpdateWs = this.handleBatchStatusUpdateWs.bind(this);
    this.handleScenarioStatusUpdateWs = this.handleScenarioStatusUpdateWs.bind(this);

    socket.on(KNOWN_EVENTS.PROGRESS_UPDATE, this.handleProgressUpdateWs);
    socket.on(KNOWN_EVENTS.BATCH_STATUS_UPDATE, this.handleBatchStatusUpdateWs);
    socket.on(KNOWN_EVENTS.SCENARIO_STATUS_UPDATE, this.handleScenarioStatusUpdateWs);
  }

  handleProgressUpdateWs(data) {
    const payload = data;
    console.log(KNOWN_EVENTS.PROGRESS_UPDATE, payload);

    const { scenarios } = this.state;
    const scenario = { ...scenarios[payload.scenario_id] || {} };
    scenario.progress = payload.progress;
    scenario.time_left = payload.time_remaining;

    this.setState({
      scenarios: {
        ...scenarios,
        [payload.scenario_id]: scenario,
      },
    });
  }

  handleBatchStatusUpdateWs(data) {
    const payload = data;
    console.log(KNOWN_EVENTS.BATCH_STATUS_UPDATE, payload);

    const { batches } = this.state;
    const batch = { ...batches[payload.batch_id] || {} };
    batch.status = payload.status;

    this.setState({
      batches: {
        ...batches,
        [payload.batch_id]: batch,
      },
    });
  }

  handleScenarioStatusUpdateWs(data) {
    const payload = data;
    console.log(KNOWN_EVENTS.SCENARIO_STATUS_UPDATE, payload);

    const { scenarios } = this.state;
    const scenario = { ...scenarios[payload.scenario_id] || {} };
    scenario.status = payload.status;

    this.setState({
      scenarios: {
        ...scenarios,
        [payload.scenario_id]: scenario,
      },
    });
  }

  render() {
    const { batches, scenarios } = this.state;

    return (
      <Auth>
        <div className="container-fluid root">
          <Router>
            <Route
              path="/"
              exact
              render={() => (
                <SocketContext.Provider value={{ batches, scenarios }}>
                  <LibraryScene />
                </SocketContext.Provider>
              )}
            />
            <Route path="/setup" component={SetupScene} />
          </Router>
        </div>
      </Auth>
    );
  }
}
