/* eslint-disable import/prefer-default-export */
const TOOL_API_HOST = process.env.REACT_APP_PUBLIC_TOOL_API_URI;
const BATCHRUNNER_API = process.env.REACT_APP_BATCHRUNNER_API;

export const auth = { token: null };

// TODO: add proper error handling (like sentry)
export async function getScenariosFromTool() {
  try {
    const resp = await fetch(`${TOOL_API_HOST}/scenarios/`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.error(e);
  }

  return new Promise();
}

export async function getBatches() {
  try {
    const resp = await fetch(`${BATCHRUNNER_API}/batch/`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.error(e);
  }

  return [];
}

export async function startBatchRun(payload) {
  try {
    const resp = await fetch(`${BATCHRUNNER_API}/batch/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return await resp.json();
  } catch (e) {
    console.error(e);
  }

  return {};
}

export async function stopBatchRun(batchID) {
  try {
    await fetch(`${BATCHRUNNER_API}/batch/${batchID}/stop`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
  } catch (e) {
    console.error(e);
  }

  return new Promise(resolve => resolve());
}

export async function getBatchScenarios(batchID) {
  try {
    const resp = await fetch(`${BATCHRUNNER_API}/batch/${batchID}/scenarios`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    const scenarios = await resp.json();
    return { batchID, scenarios };
  } catch (e) {
    console.error(e);
  }

  return { batchID, scenarios: [] };
}

export async function stopScenarioInBatchRun(scenarioID) {
  try {
    await fetch(`${BATCHRUNNER_API}/scenario/${scenarioID}/stop`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
  } catch (e) {
    console.error(e);
  }

  return new Promise(resolve => resolve());
}

export async function deleteBatchRun(batchID) {
  try {
    const resp = await fetch(`${BATCHRUNNER_API}/batch/${batchID}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
    if (resp.status === 200) {
      return true;
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}

export async function regenerateBatchResults(batchID) {
  try {
    const resp = await fetch(`${BATCHRUNNER_API}/batch/${batchID}/generate_results`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.token}`
      },
    });
    if (resp.status === 200) {
      return true;
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}