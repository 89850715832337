import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getBatchScenarios } from 'requests';

// redux
import { useSelector } from 'react-redux';
import { addBatchScenarios, useActions } from 'store';

function TableScenario({ batchID }) {
  const batchScenarios = useSelector(state => state.batchScenarios);
  const actions = useActions({ addBatchScenarios });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getScenarios() {
      await getBatchScenarios(batchID).then(actions.addBatchScenarios);
      setLoading(false);
    }

    // if we're not already, go fetch the scenario data
    if (!isLoading && !Object.prototype.hasOwnProperty.call(batchScenarios, batchID)) {
      setLoading(true);
      getScenarios();
    }
  }, [actions, isLoading, batchScenarios, batchID]);

  return null;
}
TableScenario.propTypes = {
  batchID: PropTypes.string.isRequired,
};
TableScenario.defaultProps = {
};

export default TableScenario;
