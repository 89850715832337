import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ToolTip from 'rc-tooltip';
import { getBatches } from 'requests';

// redux
import { useSelector } from 'react-redux';
import {
  useActions,
  addBatches, addBatch, updateBatchStatusWs,
  addBatchScenarios, addBatchScenarioIDsWs, updateScenarioProgressWs,
} from 'store';

// components
import { BatchesTable as Table } from 'components/table';

// utils
import { formatScenarioTimeRemaining } from 'utils';

// websockets
import { useWebsockets } from 'scenes/app';
import socket, { KNOWN_EVENTS } from '../../websocket';

import styles from './library.module.scss';
import 'rc-tooltip/assets/bootstrap.css';

export default () => {
  let batches = useSelector(state => state.batches);
  const batchScenarios = useSelector(state => state.batchScenarios);
  const actions = useActions({
    addBatches, addBatch, updateBatchStatusWs,
    addBatchScenarios, addBatchScenarioIDsWs, updateScenarioProgressWs,
  });

  // state
  const ctx = useWebsockets();

  useEffect(() => {
    getBatches().then(actions.addBatches);
  
    socket.on(KNOWN_EVENTS.NEW_SCENARIOS, (data) => {
      const payload = data;
      console.log(KNOWN_EVENTS.NEW_SCENARIOS, payload);
      actions.addBatchScenarioIDsWs(payload);
    });
  // eslint-disable-next-line
  }, []);

  // async function downloadScenarioResults(scenarioID) {
  //   const fileName = `${scenarioID}_results`;

  //   // check if the results exist first
  //   if (!await isFileAvailableInBlob(fileName)) {
  //     setScenarioResAvail({
  //       ...scenarioResAvail,
  //       [scenarioID]: false,
  //     });
  //     return;
  //   }

  //   setScenarioResAvail({
  //     ...scenarioResAvail,
  //     [scenarioID]: true,
  //   });
  // }

  const subRowComponent = (batch) => {
    const batchID = batch.batch_id;
    const scenarios = batchScenarios[batchID] || []; // if undefined, return an empty array instead
    const enabledParams = Object.entries(batch.parameters.params).reduce((arr, i) => {
      if (i[1].enabled) {
        arr.push(i[0])
      }
      return arr;
    }, []);

    return scenarios.map((scenario) => {
      const scenarioID = scenario.id;
      let status = scenario.status;
      if (ctx.scenarios[scenarioID] !== undefined) {
        const s = ctx.scenarios[scenarioID];

        if (s.status !== undefined) {
          status = s.status;
        }
      }

      // construct a string of the parameters that's changed
      // this replaces the scenario id being displayed
      const values = enabledParams.reduce((obj, param) => {
        switch (param) {
          case 's_max_steps':
          case 'max_steps':
          case 'tmax':
          case 'tmin':
          case 'budget':
            obj[param] = scenario[param];
            break;

          case 'intervention_cost':
            obj[param] = scenario.intervention_budget_change;
            break;

          case 'intervention_efficacy':
            obj[param] = scenario.efficacy_change;
            break;

          case 'risk_tss':
          case 'risk_bacteria':
          case 'risk_virus':
          case 'risk_protozoa':
            const hazard = param.substring(param.indexOf('_')+1, param.length);
            obj[param] = scenario.catchments[0][`risk_change_${hazard}`];
            break;

          case 'trap':
            obj[param] = scenario.catchments[0].pu_properties_change;
            break;

          default:
            break;
        }

        return obj;
      }, []);
      let name = Object.entries(values)
        .map(value => `${value[0]}=${value[1]}`)
        .join(', ');

      let ifDefault = null;
      if (!scenario.has_changes) {
        ifDefault = (
          <ToolTip
            placement="top"
            mouseLeaveDelay={0}
            overlay="This is the default scenario run, no properties have been changed"
          >
            <span className={styles['default-scenario']}>
              default
            </span>
          </ToolTip>
        )
      }

      let timeLeft = scenario.time_left;
      let remaining = null;
      switch (status) {
      case 'running':
        if (ctx.scenarios[scenarioID] !== undefined) {
          const s = ctx.scenarios[scenarioID];
          timeLeft = s.time_left;
        }

        remaining = `${formatScenarioTimeRemaining(timeLeft)} remaining`;
        break;

      case 'failed':
        if (scenario.progress_issues) {
          status = scenario.progress_issues.message;
        }
        break;

      case 'submitted':
        if (scenario.progress === 1) {
          status = 'resubmitting';
        }
        break;

      default:
        break;
      }

      // let isScenarioResultsAvailable = false;
      // if (status === 'completed' || status === 'stopped') {
      //   isScenarioResultsAvailable = true;

      //   if (scenarioResAvail[scenario.id] !== undefined) {
      //     isScenarioResultsAvailable = scenarioResAvail[scenario.id];
      //     if (!isScenarioResultsAvailable) {
      //       btnTooltip = 'Results not available for download';
      //     }
      //   }
      // }

      let progress = scenario.progress;
      if (ctx.scenarios[scenario.id]) {
        const s = ctx.scenarios[scenario.id];
        progress = s.progress;
      }

      // const downloadResults = () => downloadScenarioResults(scenario.id);
      // const downloadBtn = (
      //   <span>
      //     <button
      //       className="btn btn-sm btn-download"
      //       onClick={downloadResults}
      //       disabled={!isScenarioResultsAvailable}
      //     >
      //       <FontAwesomeIcon icon={faDownload} />
      //     </button>
      //   </span>
      // );

      return (
        <div
          key={scenarioID}
          className={`row ${styles['box-inside']} align-items-center`}>
          <div className="col-1">
            {ifDefault}
          </div>
          <div className="col col-sm-4">
            {name}
          </div>
          <div className="col col-md-7 col-sm-5">
            <ToolTip
              placement="top"
              mouseLeaveDelay={0}
              overlay={remaining || status}
            >
              <div className="progress">
                <div
                  className={`progress-bar ${status}`}
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="1"
                  style={{width: `${progress * 100}%`}}
                />
              </div>
            </ToolTip>
          </div>
        </div>
      );
    });
  };

  batches = batches.map(batch => {
    if (ctx.batches[batch.batch_id]) {
      const b = ctx.batches[batch.batch_id];
      batch.status = b.status;
    }
    
    return batch;
  });

  return (
    <div className="row">
      <div className="col-8 offset-2">
        <div className={styles['align-header']}>
          <Link to="/setup">
            <button
              type="button"
              className={`btn ${styles['btn-create-batch']}`}
            >
              Create new batch run
            </button>
          </Link>
        </div>
      </div>

      <div className="col-8 offset-2">
        <div className={styles['batch-table']}>
          <Table batches={batches} renderSubRowComponent={subRowComponent} context={ctx} />
        </div>
      </div>
    </div>
  );
};
