/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { CurrencyFormatter } from 'utils';
import { getScenariosFromTool, startBatchRun } from 'requests';

// redux
import { useSelector } from 'react-redux';
import { useActions, addToolScenarios } from 'store';

// components
import Loader from 'components/loader';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import styles from './setup.module.scss';

function constructObjectFromDotKey(k, v) {
  let object = {};
  const result = object;
  const arr = k.split('.');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length - 1; i++) {
    // eslint-disable-next-line no-multi-assign
    object = object[arr[i]] = {};
  }
  object[arr[arr.length - 1]] = v;

  return result;
}

const Setup = ({ location }) => {
  const scenarios = useSelector(state => state.toolScenarios);
  const actions = useActions({ addToolScenarios });

  const [selScenario, setSelectedScenario] = useState({ interventions: [] });
  const [lowBudget, setLowBudget] = useState(CurrencyFormatter.format(0));
  const [highBudget, setHighBudget] = useState(CurrencyFormatter.format(0));
  const [numOfSteps, setNumOfSteps] = useState(3);
  const [propsToRun, setPropsToRun] = useState({
    s_max_steps: { enabled: false },
    max_steps: { enabled: false },
    tmax: { enabled: false },
    tmin: { enabled: false },
    budget: { enabled: false },
    intervention_cost: { enabled: false },
    intervention_efficacy: { enabled: false },
    risk_tss: { enabled: false },
    risk_bacteria: { enabled: false },
    risk_virus: { enabled: false },
    risk_protozoa: { enabled: false },
    trap: { enabled: false },
  });
  const [goBackHome, setGoBackHome] = useState(false);
  const [batchName, setBatchName] = useState('');

  const maxAllowedRuns = 1000;
  const maxPercChange = 200;
  const minPercChange = 1;
  const nonPercProps = ['s_max_steps', 'max_steps', 'tmax', 'tmin', 'budget'];

  useEffect(() => {
    if (scenarios.length !== 0) {
      return;
    }

    // fetch the scenarios from the tool, as configured...
    getScenariosFromTool().then(actions.addToolScenarios);
  }, [scenarios, actions]);

  useEffect(() => {
    if (scenarios.length === 0) {
      return;
    }

    if (Object.keys(location.state || {}).length === 0) {
      // don't do anything, there's nothing in the state
      return;
    }

    // prefill the input fields with our pre-existing state
    const { scenario_id: scenarioId, steps, name, params } = location.state;
    // scenario id
    const sc = scenarios.find(scenario => scenario.id === scenarioId);
    setSelectedScenario(sc);

    // steps
    setNumOfSteps(steps);
    // name
    setBatchName(name);
    // lastly, params
    setPropsToRun(params);
  }, [scenarios, location.state]);

  function scenarioSelect($event) {
    // $event.target.value will be a scenario id...
    const sc = scenarios.find(scenario => scenario.id === $event.target.value);
    setSelectedScenario(sc);
  }

  function budgetChange(changeFunc) {
    const regex = RegExp('\\D');

    return ($event) => {
      // ignore anything that isn't numbers
      if (!regex.test($event.target.value)) {
        changeFunc($event.target.value);
      }
    };
  }
  function budgetPreEdit(changeFunc) {
    return ($event) => {
      // strip the leading dollar sign
      // and any commas
      let num = $event.target.value.slice(1);
      num = num.replace(/,/g, '');
      changeFunc(num);
    };
  }
  function budgetPostEdit(changeFunc, key) {
    return ($event) => {
      const formatted = CurrencyFormatter.format($event.target.value);
      changeFunc(formatted);

      // if the value is greater than 0, add it to the run props
      if (Number($event.target.value) > 0) {
        setPropsToRun({
          ...propsToRun,
          budget: {
            ...propsToRun.budget,
            [key]: Number($event.target.value),
          },
        });
      }
    };
  }

  function numOfStepsChange($event) {
    const num = Number($event.target.value);
    if (num >= 1 && num <= maxAllowedRuns) {
      setNumOfSteps(num);
    }
  }
  function validateNumOfStepsBlur($event) {
    const num = Number($event.target.value);
    if (num < 1) {
      setNumOfSteps(1);
    } else if (num > maxAllowedRuns) {
      setNumOfSteps(maxAllowedRuns);
    }
  }

  function popCheckboxOnChange(prop) {
    return ($event) => {
      setPropsToRun({
        ...propsToRun,
        [prop]: {
          ...propsToRun[prop],
          enabled: $event.target.checked,
        },
      });
    };
  }

  function propValueOnChange(prop, key) {
    return ($event) => {
      let { value } = $event.target;
      // check if the value isn't 0
      // if it's not, then we can remove its leading 0 (if there is one)
      if (Number($event.target.value) !== 0) {
        if ($event.target.value.charAt(0) === '0') {
          value = $event.target.value.substr(1);
        }
      }

      value = Number(value);
      // check if the value is within the min/max
      if (!nonPercProps.includes(prop)) {
        if (value > maxPercChange) {
          value = maxPercChange;
        } else if (value < minPercChange) {
          value = minPercChange;
        }
      }

      setPropsToRun({
        ...propsToRun,
        [prop]: {
          ...propsToRun[prop],
          [key]: value,
        },
      });
    };
  }

  function batchNameChange($event) {
    setBatchName($event.target.value);
  }

  function goBack() {
    // navigate back to the home page to view our batches
    setGoBackHome(true);
  }

  async function runBatch() {
    // create the payload object
    const payload = {
      name: batchName,
      scenario_id: selScenario.id,
      steps: numOfSteps,
      params: propsToRun,
    };

    await startBatchRun(payload);

    // navigate back to the home page to view our batches
    setGoBackHome(true);
  }

  if (scenarios.length === 0) {
    return <Loader message="loading scenarios" />;
  }

  if (goBackHome) {
    return <Redirect to="/" />;
  }

  // create our options for each scenario
  // grouping them by their visibility as defined in the main tool app
  let options = [...new Set(scenarios.map(s => s.visibility))];
  options = options.filter(v => ['reviewing', 'published'].includes(v));
  options = options.map((visibility) => {
    let matchingVisScens = scenarios.filter(s => s.visibility === visibility);
    matchingVisScens = matchingVisScens.map(s => (
      <option key={s.id} value={s.id} selected={selScenario.id === s.id}>
        {s.name}
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        {s.description.substring(0, 100)}
      </option>
    ));

    let label = visibility.toLowerCase();
    label = label[0].toUpperCase() + label.slice(1); // uppercase the first letter

    return (
      <optgroup label={label} key={visibility}>
        {matchingVisScens}
      </optgroup>
    );
  });

  // construct select options from scenario list
  // first get all the statuses, run them through a set to remove duplicates
  // then reformat them as optgroups with option children
  // const selectOptions = [...new Set(scenarios.map(scenario => scenario.status))]
  //   .map((status) => {
  //     let scs = scenarios.filter(scenario => scenario.status === status);
  //     scs = scs.map(sc => (
  //       <option key={sc.id} value={sc.id}>
  //         {sc.name}
  //         &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
  //         {sc.description.substring(0, 100)}
  //       </option>
  //     ));

  //     let label = status.toLowerCase();
  //     if (label === '') {
  //       label = 'not ran';
  //     }

  //     label = label[0].toUpperCase() + label.slice(1); // uppercase the first letter
  //     return (
  //       <optgroup label={label} key={label}>
  //         {scs}
  //       </optgroup>
  //     );
  //   });

  const formattedBudget = CurrencyFormatter.format(selScenario.budget || 0);

  // figure out how many runs this batch will generate
  const enabledProps = Object.entries(propsToRun)
    .filter(keyValue => keyValue[1].enabled === true).length;
  const matrix = new Array(enabledProps);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < matrix.length; i++) {
    matrix[i] = new Array(numOfSteps + 1);
  }

  let numOfRunsCalculated = 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < matrix.length; i++) {
    numOfRunsCalculated *= matrix[i].length;
  }

  return (
    <div>
      <div className="row pl-3 pr-3 align-content-center">
        <div className={`col-1 ${styles['back-icon']}`} onClick={goBack}>
          <FontAwesomeIcon
            icon={faLongArrowLeft}
            size="2x"
            title="Go back to the batch library"
          />
        </div>
      </div>

      <div className="row pl-3 pr-3 align-content-center">
        {/* scenario select */}
        <div className="col-6">
          <div className="form-group">
            <label>Pick a scenario to use as a base</label>
            <select
              className={`browser-default custom-select ${styles.long}`}
              onChange={scenarioSelect}
              defaultValue={selScenario.name}
            >
              <option disabled value="">Choose a scenario</option>
              {options}
            </select>
          </div>
        </div>

        {/* batch name */}
        <div className="col-4">
          <div className="form-group">
            <label>Batch name</label>
            <input
              type="text"
              className={`form-control ${styles.long}`}
              placeholder="Give your batch a name"
              onChange={batchNameChange}
              value={batchName}
            />
          </div>
        </div>

        {/* number of runs */}
        <div className="col-2 align-self-center text-center">
          <div className="form-group">
            <label />
            <h5>
              {numOfRunsCalculated}
              &nbsp;/&nbsp;
              {maxAllowedRuns}
              <span className={styles['run-count-suffix']}>runs</span>
            </h5>
          </div>
        </div>
      </div>

      <div className="row pl-3 pr-3 justify-content-between align-content-center">
        {/* scenario description */}
        <div className="col-6">
          <div className="form-group">
            <label>Description</label>
            <textarea
              className={`form-control ${styles.description}`}
              value={selScenario.description}
              readOnly
            />
          </div>
        </div>

        {/* number of steps */}
        <div className="col">
          <div className="form-group">
            <label># of steps</label>
            <input
              type="number"
              className="form-control"
              onChange={numOfStepsChange}
              onBlur={validateNumOfStepsBlur}
              value={numOfSteps}
              min="1"
              max={maxAllowedRuns}
            />
          </div>
        </div>

        {/* trigger run */}
        <div className="col-2 align-self-center">
          <button
            type="button"
            className={`btn btn-block ${styles['btn-run']}`}
            onClick={runBatch}
            disabled={enabledProps === 0}
          >
            Run
          </button>
        </div>
        {/* <div className="col1 align-self-center">
          <button
            type="button"
            className={`btn btn-block ${styles['btn-save']}`}
            disabled={enabledProps === 0}
          >
            Save
          </button>
        </div> */}
      </div>

      {/* optimisation settings */}
      <div className="row mt-3 align-content-center">
        <div className="col-6 mb-3 text-center">
          <h3 className={styles.titleHeader}>Optimisation Settings</h3>
        </div>
        <div className="col-6 mb-3 text-center">
          <h3 className={styles.titleHeader}>Budget</h3>
        </div>
        <div className="w-100" />

        {/* optimisation s_max_steps */}
        <div className="col-2 pl-5">
          <div className="form-group mb-4">
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="s_max_steps"
                  checked={propsToRun.s_max_steps.enabled}
                  onChange={popCheckboxOnChange('s_max_steps')}
                />
                <label className="form-check-label" htmlFor="s_max_steps">
                  s_max_steps
                </label>
              </div>
            </label>
            <input
              type="number"
              className={`form-control ${styles['base-value']}`}
              value={selScenario.s_max_steps}
              disabled
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>Lower</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('s_max_steps', 'lower')}
              value={Number(propsToRun.s_max_steps.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>Upper</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('s_max_steps', 'upper')}
              value={Number(propsToRun.s_max_steps.upper).toString()}
            />
          </div>
        </div>

        {/* budget */}
        <div className="col-2 pl-5">
          <div className={`form-group ${styles.budget}`}>
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="budget"
                  checked={propsToRun.budget.enabled}
                  onChange={popCheckboxOnChange('budget')}
                />
                <label className="form-check-label" htmlFor="budget">
                  Budget
                </label>
              </div>
            </label>
            <input
              type="text"
              className={`form-control ${styles.budget} ${styles['base-value']}`}
              value={formattedBudget}
              disabled
            />
          </div>
        </div>
        <div className="col-2">
          <div className={`form-group ${styles.lowerBudget}`}>
            <label>Lower budget</label>
            <input
              type="text"
              className="form-control"
              value={lowBudget}
              onChange={budgetChange(setLowBudget)}
              onFocus={budgetPreEdit(setLowBudget)}
              onBlur={budgetPostEdit(setLowBudget, 'lower')}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className={`form-group ${styles.higherBudget}`}>
            <label>Upper budget</label>
            <input
              type="text"
              className="form-control"
              value={highBudget}
              onChange={budgetChange(setHighBudget)}
              onFocus={budgetPreEdit(setHighBudget)}
              onBlur={budgetPostEdit(setHighBudget, 'upper')}
            />
          </div>
        </div>

        {/* optimisation max_steps */}
        <div className="col-2 pl-5">
          <div className="form-group mb-4">
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="max_steps"
                  checked={propsToRun.max_steps.enabled}
                  onChange={popCheckboxOnChange('max_steps')}
                />
                <label className="form-check-label" htmlFor="max_steps">
                  max_steps
                </label>
              </div>
            </label>
            <input
              type="number"
              className={`form-control ${styles['base-value']}`}
              value={selScenario.max_steps}
              disabled
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>Lower</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('max_steps', 'lower')}
              value={Number(propsToRun.max_steps.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>Upper</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('max_steps', 'upper')}
              value={Number(propsToRun.max_steps.upper).toString()}
            />
          </div>
        </div>

        <div className="col-6 mb-3 mt-3 text-center">
          <h3 className={styles.titleHeader}>
            Interventions&nbsp;
            -&nbsp;
            {selScenario.interventions.filter(i => i.allow).length}
            &nbsp;/&nbsp;
            {selScenario.interventions.length}
          </h3>
        </div>

        {/* optimisation tmax */}
        <div className="col-2 pl-5">
          <div className="form-group mb-4">
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tmax"
                  checked={propsToRun.tmax.enabled}
                  onChange={popCheckboxOnChange('tmax')}
                />
                <label className="form-check-label" htmlFor="tmax">
                  tmax
                </label>
              </div>
            </label>
            <input
              type="number"
              className={`form-control ${styles['base-value']}`}
              value={selScenario.tmax}
              disabled
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>Lower</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('tmax', 'lower')}
              value={Number(propsToRun.tmax.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>Upper</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('tmax', 'upper')}
              value={Number(propsToRun.tmax.upper).toString()}
            />
          </div>
        </div>

        {/* intervention cost */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="intervention_cost"
              checked={propsToRun.intervention_cost.enabled}
              onChange={popCheckboxOnChange('intervention_cost')}
            />
            <label className="form-check-label" htmlFor="intervention_cost">
              Cost
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('intervention_cost', 'lower')}
              value={Number(propsToRun.intervention_cost.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('intervention_cost', 'upper')}
              value={Number(propsToRun.intervention_cost.upper).toString()}
            />
          </div>
        </div>

        {/* optimisation tmin */}
        <div className="col-2 pl-5">
          <div className="form-group mb-4">
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tmin"
                  checked={propsToRun.tmin.enabled}
                  onChange={popCheckboxOnChange('tmin')}
                />
                <label className="form-check-label" htmlFor="tmin">
                  tmin
                </label>
              </div>
            </label>
            <input
              type="number"
              className={`form-control ${styles['base-value']}`}
              value={selScenario.tmin}
              disabled
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>Lower</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('tmin', 'lower')}
              value={Number(propsToRun.tmin.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>Upper</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('tmin', 'upper')}
              value={Number(propsToRun.tmin.upper).toString()}
            />
          </div>
        </div>

        {/* intervention efficacy */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="intervention_efficacy"
              checked={propsToRun.intervention_efficacy.enabled}
              onChange={popCheckboxOnChange('intervention_efficacy')}
            />
            <label className="form-check-label" htmlFor="intervention_efficacy">
              Efficacy
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('intervention_efficacy', 'lower')}
              value={Number(propsToRun.intervention_efficacy.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('intervention_efficacy', 'upper')}
              value={Number(propsToRun.intervention_efficacy.upper).toString()}
            />
          </div>
        </div>

        <div className="w-100" />
      </div>

      {/* risk & trap scaling */}
      <div className="row mt-3 align-content-center">
        <div className="col-6 mb-3 text-center">
          <h3 className={styles.titleHeader}>Risk @ WTP</h3>
        </div>
        <div className="col-6 mb-3 text-center">
          <h3 className={styles.titleHeader}>Trap scaling</h3>
        </div>
        <div className="w-100" />

        {/* risk tss */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="risk_tss"
              checked={propsToRun.risk_tss.enabled}
              onChange={popCheckboxOnChange('risk_tss')}
            />
            <label className="form-check-label" htmlFor="risk_tss">
              TSS
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_tss', 'lower')}
              value={Number(propsToRun.risk_tss.lower).toString()}
              max={maxPercChange}
              min={minPercChange}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_tss', 'upper')}
              value={Number(propsToRun.risk_tss.upper).toString()}
              max={maxPercChange}
              min={minPercChange}
            />
          </div>
        </div>

        {/* trap scaling */}
        <div className="col-3 pl-5">
          <div className="form-group">
            <label>
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="trap"
                  checked={propsToRun.trap.enabled}
                  onChange={popCheckboxOnChange('trap')}
                />
                <label className="form-check-label" htmlFor="trap">
                  Lower -%
                </label>
              </div>
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('trap', 'lower')}
              value={Number(propsToRun.trap.lower).toString()}
            />
          </div>
        </div>
        <div className="col-3 pr-5">
          <div className="form-group">
            <label>Upper +%</label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('trap', 'upper')}
              value={Number(propsToRun.trap.upper).toString()}
            />
          </div>
        </div>

        <div className="w-100" />

        {/* risk bacteria */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="risk_bacteria"
              checked={propsToRun.risk_bacteria.enabled}
              onChange={popCheckboxOnChange('risk_bacteria')}
            />
            <label className="form-check-label" htmlFor="risk_bacteria">
              Bacteria
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_bacteria', 'lower')}
              value={Number(propsToRun.risk_bacteria.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_bacteria', 'upper')}
              value={Number(propsToRun.risk_bacteria.upper).toString()}
            />
          </div>
        </div>

        <div className="w-100" />

        {/* risk virus */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="risk_virus"
              checked={propsToRun.risk_virus.enabled}
              onChange={popCheckboxOnChange('risk_virus')}
            />
            <label className="form-check-label" htmlFor="risk_virus">
              Virus
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_virus', 'lower')}
              value={Number(propsToRun.risk_virus.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_virus', 'upper')}
              value={Number(propsToRun.risk_virus.upper).toString()}
            />
          </div>
        </div>

        <div className="w-100" />

        {/* risk protozoa */}
        <div className="col-2 pl-5">
          <div className="form-check pl-0">
            <input
              className="form-check-input"
              type="checkbox"
              id="risk_protozoa"
              checked={propsToRun.risk_protozoa.enabled}
              onChange={popCheckboxOnChange('risk_protozoa')}
            />
            <label className="form-check-label" htmlFor="risk_protozoa">
              Protozoa
            </label>
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label>
              Lower -%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_protozoa', 'lower')}
              value={Number(propsToRun.risk_protozoa.lower).toString()}
            />
          </div>
        </div>
        <div className="col-2 pr-5">
          <div className="form-group">
            <label>
              Upper +%
            </label>
            <input
              type="number"
              className="form-control"
              onChange={propValueOnChange('risk_protozoa', 'upper')}
              value={Number(propsToRun.risk_protozoa.upper).toString()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
Setup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default Setup;
