/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'rc-tooltip';
import {
  stopBatchRun as stopBatchRunRequest,
  deleteBatchRun,
  regenerateBatchResults,
} from 'requests';
import { useHistory } from 'react-router-dom';

// utils
import { isFileAvailableInBlob, downloadFileFromBlob } from 'utils';

// redux
import { useActions, removeBatch } from 'store';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandPaper, faWrench, faEllipsisV, faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird as faSpinnerThirdDuoTone, faCog as faCogDuoTone } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck as faCheckRegular } from '@fortawesome/pro-regular-svg-icons';

// components
import FetchScenariosInTable from 'components/scenarios';
import { Dropdown } from 'react-bootstrap';

import './batches.table.scss';

function BatchesTable({ batches, renderSubRowComponent }) {
  const actions = useActions({ removeBatch });
  const history = useHistory();

  const [batchesStatus, setBatchesStatus] = useState({}); // batch status override
  const [expandedBatches, setExpandedBatches] = useState({}); // batch expanded state
  const [batchResAvail, setBatchResAvail] = useState({}); // batch results available

  async function downloadBatchResults(batchID, downloadName) {
    const fileName = `${batchID}_results`;

    // check if the results exist first
    if (!await isFileAvailableInBlob(fileName)) {
      setBatchResAvail({
        ...batchResAvail,
        [batchID]: false,
      });
      return;
    }

    const blob = await downloadFileFromBlob(fileName);
    const url = window.URL.createObjectURL(blob);
    const href = document.createElement('a');
    href.href = url;
    href.download = `${downloadName}.csv`;
    href.click();
  }

  async function stopBatchRun(batchID) {
    setBatchesStatus({ ...batchesStatus, [batchID]: 'stopping' });
    // send a request to the api to stop it
    await stopBatchRunRequest(batchID);
  }

  function toggleBatchExpand(batchID) {
    let isExpanded = false;
    if (expandedBatches[batchID]) {
      isExpanded = expandedBatches[batchID];
    }

    setExpandedBatches({ ...expandedBatches, [batchID]: !isExpanded });
  }

  function stopPropagation($event) {
    $event.stopPropagation();
  }

  async function deleteBatch(batchID) {
    setBatchesStatus({ ...batchesStatus, [batchID]: 'deleting' });
    const result = await deleteBatchRun(batchID);
    if (result) {
      // remove the batch from the store
      actions.removeBatch(batchID);
    }
  }

  async function copyBatch(params) {
    // navigate to the new batch page with our state
    history.push('/setup', params);
  }

  async function regenBatchResults(batchID) {
    setBatchesStatus({ ...batchesStatus, [batchID]: 'regenerating' });
    const result = await regenerateBatchResults(batchID);
    if (result) {
      // well it's done, so we don't need to do anything fancy
    }
  }

  const rows = batches.map((batch) => {
    const batchID = batch.batch_id;

    let batchName = batch.name;
    if (batchName === '') {
      batchName = batchID;
    }

    // icon changes
    let statusIcon = faSpinnerThirdDuoTone;
    let iconShouldSpin = true;
    let iconClassName = '';

    let isResultsAvailable = false;
    let resultsNotAvailableStatusTooltip = '';
    let isBatchRunning = false;
    let isBatchStopping = false;
    switch (batch.status) {
    case 'creating':
      statusIcon = faWrench;
      iconClassName = 'faa-wrench animated';
      break;

    case 'running':
      isBatchRunning = true;
      break;

    case 'stopping':
      statusIcon = faHandPaper;
      iconClassName = 'faa-flash animated';
      isBatchStopping = true;

      // clear the status override for the batch
      if (batchesStatus[batchID] !== undefined) {
        setBatchesStatus({ ...batchesStatus, [batchID]: undefined });
      }
      break;

    case 'post-processing':
      statusIcon = faCogDuoTone;
      // clear the status override for the batch
      if (batchesStatus[batchID] !== undefined) {
        setBatchesStatus({ ...batchesStatus, [batchID]: undefined });
      }
      break;

    case 'completed':
      isResultsAvailable = true;
      if (batchResAvail[batchID] !== undefined) {
        isResultsAvailable = batchResAvail[batchID];
        if (!isResultsAvailable) {
          resultsNotAvailableStatusTooltip = 'results not available for download';
        }
      }

      statusIcon = faCheckRegular;
      iconShouldSpin = false;
      break;

    case 'stopped':
      isResultsAvailable = true;
      if (batchResAvail[batchID] !== undefined) {
        isResultsAvailable = batchResAvail[batchID];
        if (!isResultsAvailable) {
          resultsNotAvailableStatusTooltip = 'results not available for download';
        }
      }

      statusIcon = faStopCircle;
      iconShouldSpin = false;
      break;

    default:
      break;
    }

    let { status } = batch;
    if (batchesStatus[batchID] !== undefined) {
      const newStatus = batchesStatus[batchID];
      if (newStatus === 'stopping' && batch.status === 'running') {
        isBatchRunning = false;
        isBatchStopping = true;
        status = newStatus;
        statusIcon = faHandPaper;
        iconClassName = 'faa-flash animated';
      }
    }

    const shouldExpand = expandedBatches[batchID] || false;

    const toggleExpand = () => toggleBatchExpand(batchID);
    const downloadResults = ($event) => {
      $event.stopPropagation();
      let id = batchID;
      if (batch.name !== '') {
        id = batch.name;
      }

      downloadBatchResults(batchID, id);
    };
    const stopRun = ($event) => {
      $event.stopPropagation();
      stopBatchRun(batchID);
    };

    const deleteBatchClick = () => {
      deleteBatch(batchID);
    };

    const copyBatchClick = () => {
      copyBatch(batch.parameters);
    };

    const regenBatchResultsClick = () => {
      regenBatchResults(batchID);
    };

    let downloadBtn = (
      // wrapped in a span because rc-tooltip has a bug where the tooltip won't dismiss on a disabled button
      <span>
        <button
          type="button"
          className="btn btn-download"
          onClick={downloadResults}
          disabled={!isResultsAvailable}
        >
          Download results
        </button>
      </span>
    );
    if (isResultsAvailable) {
      if (resultsNotAvailableStatusTooltip !== '') {
        downloadBtn = (
          <ToolTip
            placement="top"
            mouseLeaveDelay={0}
            overlay={resultsNotAvailableStatusTooltip}
          >
            {downloadBtn}
          </ToolTip>
        );
      }
    } else {
      downloadBtn = null;
    }

    return (
      <React.Fragment key={batchID}>
        <tr>
          <td>
            <div role="cell" className="box" onClick={toggleExpand}>
              <div className="row justify-content-end align-items-center">
                <div className="col-lg-6 col-sm-5">
                  {batchName}
                </div>

                <div className="col text-right">
                  {/* {isBatchRunning && (
                    <button
                      type="button"
                      className="btn btn-stop"
                      onClick={stopRun}
                      disabled={!isBatchRunning || isBatchStopping}
                    >
                      Stop
                    </button>
                  )}
                  &nbsp;&nbsp; */}
                  {downloadBtn}
                  <span className="batch-status">
                    <ToolTip
                      placement="top"
                      mouseLeaveDelay={0}
                      overlay={status}
                    >
                      <FontAwesomeIcon
                        size="2x"
                        spin={iconShouldSpin}
                        icon={statusIcon}
                        className={iconClassName}
                      />
                    </ToolTip>
                  </span>

                  <Dropdown onClick={stopPropagation} className="dropdown-inline">
                    <Dropdown.Toggle className="btn-options" variant="">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={deleteBatchClick}
                        disabled={isBatchStopping}
                      >
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={copyBatchClick}
                      >
                        Copy
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={regenBatchResultsClick}
                      >
                        Regenerate results
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </td>
        </tr>
        {shouldExpand && (
          <>
            <FetchScenariosInTable batchID={batchID} />
            <tr>
              <td>
                {renderSubRowComponent(batch)}
              </td>
            </tr>
          </>
        )}
      </React.Fragment>
    );
  });

  return (
    <table>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}
BatchesTable.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSubRowComponent: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
};
BatchesTable.defaultProps = {
  renderSubRowComponent: () => null,
};

export default BatchesTable;
